exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-affiliate-disclosure-js": () => import("./../../../src/pages/affiliate-disclosure.js" /* webpackChunkName: "component---src-pages-affiliate-disclosure-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-electronic-gadgets-js": () => import("./../../../src/pages/electronic-gadgets.js" /* webpackChunkName: "component---src-pages-electronic-gadgets-js" */),
  "component---src-pages-home-appliances-js": () => import("./../../../src/pages/home-appliances.js" /* webpackChunkName: "component---src-pages-home-appliances-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-latest-posts-js": () => import("./../../../src/pages/latest-posts.js" /* webpackChunkName: "component---src-pages-latest-posts-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-smart-home-js": () => import("./../../../src/pages/smart-home.js" /* webpackChunkName: "component---src-pages-smart-home-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-templates-info-post-json-template-js": () => import("./../../../src/templates/info-post-json-template.js" /* webpackChunkName: "component---src-templates-info-post-json-template-js" */),
  "component---src-templates-product-post-json-template-js": () => import("./../../../src/templates/product-post-json-template.js" /* webpackChunkName: "component---src-templates-product-post-json-template-js" */)
}

